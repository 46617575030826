import React, { Suspense, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AuthContext from './contexts/authProvider';
import SettingContext from './contexts/settingProvider';
import Login from './pages/Login';
import Header from './components/Header';
import Footer from './components/Footer';
import Auth from './pages/Auth';

const NotFound = React.lazy(() => import('./pages/NotFound'));
const AQFormlist = React.lazy(() => import('./pages/AQFormlist'));
const Settings = React.lazy(() => import('./pages/Settings'));
const AQForm = React.lazy(() => import('./pages/AQForm'));
const UserInfo = React.lazy(() => import('./pages/UserInfo'));

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);

function NavWrapper() {
  const { user } = useContext(AuthContext);
  const { dark } = useContext(SettingContext);
  if (!user) return <Navigate to="/login" />;

  return (
    <Layout id="ant-main-layout" className={`app-layout ${dark ? 'dark' : ''}`}>
      <Header />
      <Suspense
        fallback={
          <div className="app-content loading" style={{ width: '100%' }}>
            <Spin indicator={antIcon} />
          </div>
        }
      >
        <Outlet />
      </Suspense>
      <Footer />
    </Layout>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/" element={<NavWrapper />}>
          <Route path="/" element={<AQFormlist />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/aqForm" element={<AQFormlist />} />
          <Route path="/aqForm/:form_type" element={<AQForm />} />
          <Route path="/userInfo" element={<UserInfo />} />
        </Route>
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
