import React, { useContext, useEffect, useState, useCallback } from 'react';
import useRWD from '../../hooks/useRWD';
import { useNavigate } from 'react-router-dom';
import { Button, message } from 'antd';
import AuthContext, { baseAuthUrl } from '../../contexts/authProvider';
import bg from '../../images/loginbg.jpg';
import bg_m from '../../images/loginbg_m.jpg';
import logo from '../../images/logo_login.svg';
import google_logo from '../../images/google_logo.svg';

const Login = () => {
  const { user, AuthUser } = useContext(AuthContext);
  const device = useRWD();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleAuthError = useCallback((error) => {
    console.error('Authentication error:', error);
    if (error.status_code === 403) {
      message.error('您沒有權限使用此應用程序。請聯繫管理員。');
    } else {
      message.error(error.error || '登錄時發生錯誤。請稍後再試。');
    }
    setIsLoading(false);
  }, []);

  const openNewWindowButton = useCallback(() => {
    setIsLoading(true);
    const url = `${baseAuthUrl}/aq-login`;
    const width = 800;
    const height = 600;

    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const name = '_blank';
    const specs = `width=${width},height=${height},left=${left},top=${top}`;

    return window.open(url, name, specs);
  }, []);

  useEffect(() => {
    if (user) {
      navigate('/');
    }

    const handleMessage = (event) => {
      if (event.origin === window.location.origin) {
        const data = event.data;
        if (data.type === 'auth_success') {
          AuthUser(data.data);
          setIsLoading(false);
        } else if (data.type === 'auth_error') {
          handleAuthError(data.error);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [user, navigate, AuthUser, handleAuthError]);

  return (
    <div
      className="app-login"
      style={
        device !== 'hisesPC' && device !== 'PC'
          ? { backgroundImage: `url(${bg_m})` }
          : { backgroundImage: `url(${bg})` }
      }
    >
      <div className="app-login-content">
        <div className="container">
          <div className="app-login-form">
            <div className="logo">
              <img src={logo} alt="微電能源" />
            </div>
            <Button
              onClick={openNewWindowButton}
              icon={<img alt="google" width="14" height="14" src={google_logo} />}
              size="large"
              block
              className="app-login-btn-ghost"
              loading={isLoading}
            >
              {isLoading ? '登入中...' : '用Google SSO登入'}
            </Button>
          </div>
        </div>
      </div>
      <div className="app-login-footer">
        <div className="container">
          <p>© {new Date().getFullYear()} Micro Electricity Co., Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
