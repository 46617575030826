import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { baseAuthUrl } from '../../contexts/authProvider';
import axios from 'axios';

const LoadingIcon = (
  <LoadingOutlined
    style={{
      fontSize: 48,
    }}
    spin
  />
);

const Auth = () => {
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      axios
        .get(`${baseAuthUrl}/secure-auth${search}`)
        .then((response) => {
          if (response && response.data) {
            if (response.data.status_code && response.data.status_code === 403) {
              handleError(response.data);
            } else if (response.data.token) {
              if (window.opener) {
                window.opener.postMessage(
                  { type: 'auth_success', data: response.data },
                  window.location.origin
                );
                window.close();
              }
            } else {
              console.error('Unexpected response format:', response.data);
              handleError(response.data || { error: 'Unexpected response format' });
            }
          } else {
            console.error('Unexpected response format: No response data');
            handleError({ error: 'Unexpected response format' });
          }
        })
        .catch((error) => {
          console.error('Axios error:', error);
          handleError({ error: error.message });
        });
    }
  }, [search]);

  const handleError = (errorData) => {
    if (window.opener) {
      window.opener.postMessage({ type: 'auth_error', error: errorData }, window.location.origin);
      window.close();
    }
  };

  return (
    <div>
      <Row
        justify="center"
        align="middle"
        gutter={[
          { xs: 16, sm: 16, md: 16, lg: 16 },
          { xs: 16, sm: 16, md: 16, lg: 16 },
        ]}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
            }}
          >
            <div>
              <Spin indicator={LoadingIcon} />
              <span style={{ display: 'block', marginTop: 16, color: 'rgba(0,0,0,0.65)' }}>載入中</span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Auth;
