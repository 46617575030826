import React, { createContext, useContext, useState, useCallback } from 'react';

const FormOptionsContext = createContext();
function getClosestPastTime(combinations) {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();

  let closestHour = combinations.hourTime[0];
  let closestQuarter = combinations.quarter[0];

  // Find closest past hour
  for (let i = combinations.hourTime.length - 1; i >= 0; i--) {
    if (combinations.hourTime[i].value <= currentHour) {
      closestHour = combinations.hourTime[i];
      break;
    }
  }

  // Find closest past quarter
  for (let i = combinations.quarter.length - 1; i >= 0; i--) {
    if (combinations.quarter[i].value <= currentMinutes) {
      closestQuarter = combinations.quarter[i];
      break;
    }
  }

  return {
    hourTime: closestHour,
    quarter: closestQuarter,
  };
}

export const useFormOptions = () => useContext(FormOptionsContext);

export const FormOptionsProvider = ({ children }) => {
  const [dynamicOptions, setDynamicOptions] = useState({
    timeOptions: [], // 假設時間選項需要動態加載
  });
  const generateTimeArray = () =>
    Array.from({ length: 24 }, (_, i) => ({
      value: i,
      label: `${i < 6 ? '凌晨' : i < 12 ? '早上' : i < 13 ? '中午' : i < 18 ? '下午' : '晚上'} ${i}點`,
    }));

  // 固定的選項
  const staticOptions = {
    activityLevels: ['順流', '逆流', '集中', '其他'], // 活動力選項
    intestinalObservations: ['飽滿', '斷腸', '通腸', '其他'], // 腸道觀察選項
    waterColor: [
      "清澈透明",
      "淡綠色",
      "綠色",
      "深綠色",
      "棕色",
      "淡棕色",
      "灰色",
      "黃色",
      "藍綠色",
      "紅色",
      "乳白色",
      "黑色"
    ],
    feeds: [
      "蝦飼料-粉料",
      "蝦飼料-0號",
      "蝦飼料-1號",
      "蝦飼料-2號",
      "蝦飼料-3號",
      "蝦飼料-4號",
      "蝦飼料-5號"
    ],
    hours: generateTimeArray(),
    quarter: [
      { label: '整點', value: 0 },
      { label: '15分', value: 15 },
      { label: '30分', value: 30 },
      { label: '45分', value: 45 },
    ],
    ill_type: [
      '請選擇',
      '死亡發生',
      '行為異常',
      '外觀異常',
      '鰓部異常',
      '肝胰臟病變',
      '肌肉病變',
      '眼睛病變',
      '疾病名稱',
      '寄生蟲感染',
      '外型異常',
      '體表病變',
      '鰓部病變',
      '內臟病變',
      '糞便異常',
      '鏡檢情形',
    ],
    ill_type_overall: ['主要疾病', '次要疾病'],
  };

  const defaultValues = {
    timePicker: getClosestPastTime({
      hourTime: staticOptions.hours,
      quarter: staticOptions.quarter,
    }),
  };

  const fetchDynamicOptions = useCallback(() => {
    // 模擬從 API 獲取數據
    setTimeout(() => {
      setDynamicOptions({
        timeOptions: ['早上', '下午', '晚上'],
      });
    }, 1000); // 假設有 1 秒的延遲來模擬異步請求
  }, []);

  // const formTypeMapList = [
  //   {
  //     form_type: 'water_quality',
  //     forms: ['B01 水質測試', 'B03 培養水質', 'D01 水質檢測'],
  //   },
  //   {
  //     form_type: 'disinfection_and_maintenance',
  //     forms: ['B02 消毒作業', 'D02 保養殺菌'],
  //   },
  //   {
  //     form_type: 'fish_test_water_record',
  //     forms: ['C01 試苗', 'C02 入苗'],
  //   },
  //   {
  //     form_type: 'daily_feeding_record',
  //     forms: ['D06 投餵紀錄'],
  //   },
  //   {
  //     form_type: 'daily_growth_record',
  //     forms: ['D07 巡視生長'],
  //   },
  //   {
  //     form_type: 'daily_inspection_record',
  //     forms: ['D08 環境巡查'],
  //   },
  //   {
  //     form_type: 'daily_medication_record',
  //     forms: ['D09 巡視用藥'],
  //   },
  //   {
  //     form_type: 'aquaculture_prodcution',
  //     forms: ['E01 產量評估'],
  //   },
  // ];

  return (
    <FormOptionsContext.Provider
      value={{ staticOptions, dynamicOptions, fetchDynamicOptions, defaultValues }}
    >
      {children}
    </FormOptionsContext.Provider>
  );
};
