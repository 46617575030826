import React, { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Layout, Dropdown } from 'antd';
import { SettingOutlined, PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import AuthContext from '../../contexts/authProvider';
import SettingContext from '../../contexts/settingProvider';
import logo from '../../images/logo_s.svg';
import logo_b from '../../images/logo_b.svg';

const { Header } = Layout;

const AppHeader = () => {
  const { dark } = useContext(SettingContext);
  const { user, UnAuthUser } = useContext(AuthContext);

  const userItems = useMemo(
    () => [
      {
        key: 'userInfo',
        label: <Link to="/userInfo">使用者資訊</Link>,
        icon: (
          <Link to="/userInfo">
            <UserOutlined />
          </Link>
        ),
        name: '使用者資訊',
        path: '/userInfo',
      },
      {
        key: 'environment',
        label: <Link to="/settings">環境設定</Link>,
        icon: (
          <Link to="/settings">
            <SettingOutlined />
          </Link>
        ),
        name: '環境設定',
        path: '/settings',
      },
      {
        type: 'divider',
      },
      {
        key: 'logout',
        label: <span onClick={() => UnAuthUser()}>登出</span>,
        icon: (
          <span onClick={() => UnAuthUser()}>
            <PoweroffOutlined />
          </span>
        ),
        name: '登出',
        path: '',
      },
    ],
    [UnAuthUser]
  );

  return (
    <Header className="app-header">
      <div className="app-header-container">
        <div className="app-header-left">
          <div className="app-header-logo">
            <Link to="/">
              <img src={dark ? logo : logo_b} alt="微電能源" />
            </Link>
          </div>
        </div>

        <ul className="app-header-right">
          <li>
            <div className="user">
              <Dropdown placement="bottom" menu={{ items: userItems }} trigger={['click']}>
                <div>
                  <Badge size="small" count={0}>
                    <Avatar size="small" src={user?.picture} />
                  </Badge>
                  <span className="name">{user?.given_name}</span>
                </div>
              </Dropdown>
            </div>
          </li>
        </ul>
      </div>
    </Header>
  );
};

export default AppHeader;
